import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

const LoadMoreDetector = ({ fetchMore, rootMargin }) => {
  const [reference, inView] = useInView({
    rootMargin,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      fetchMore();
    }
  }, [inView, fetchMore]);

  return (
    <div ref={reference} style={{ flexGrow: 1 }} />
  );
};

LoadMoreDetector.propTypes = {
  fetchMore: PropTypes.func.isRequired,
  rootMargin: PropTypes.string,
};

LoadMoreDetector.defaultProps = {
  rootMargin: '512px',
};

const LoadMore = ({
  loading, hasNextPage, fetchMore, rootMargin,
}) => (
  !loading && hasNextPage ? (
    <LoadMoreDetector
      fetchMore={fetchMore}
      rootMargin={rootMargin}
    />
  ) : null
);

LoadMore.propTypes = {
  loading: PropTypes.bool.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func.isRequired,
  rootMargin: PropTypes.string,
};

LoadMore.defaultProps = {
  rootMargin: '512px',
};

export default LoadMore;
