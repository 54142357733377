import { getTargetOrigin } from './redirectRouteToDomain';

const targetOriginSuffixes = {
  'cs-CZ': 'fashionunited.cz',
  'da-DK': 'fashionunited.dk',
  'de-AT': 'fashionunited.at',
  'de-CH': 'fashionunited.ch',
  'de-DE': 'fashionunited.de',
  'en-AU': 'au.fashionunited.com',
  'en-CA': 'fashionunited.ca',
  'en-GB': 'fashionunited.uk',
  'en-IE': 'fashionunited.ie',
  'en-IN': 'fashionunited.in',
  'en-NZ': 'fashionunited.nz',
  'en-US': 'fashionunited.com',
  'es-AR': 'fashionunited.com.ar',
  'es-CL': 'fashionunited.cl',
  'es-CO': 'fashionunited.co',
  'es-ES': 'fashionunited.es',
  'es-MX': 'fashionunited.mx',
  'es-PE': 'fashionunited.com.pe',
  'fi-FI': 'fashionunited.fi',
  'fr-BE': 'fashionunited.be',
  'fr-CA': 'fashionunited.ca',
  'fr-CH': 'fashionunited.ch',
  'fr-FR': 'fashionunited.fr',
  'fr-LU': 'fashionunited.lu',
  'hu-HU': 'fashionunited.hu',
  'it-CH': 'fashionunited.ch',
  'it-IT': 'fashionunited.it',
  'ja-JP': 'fashionunited.jp',
  'nb-NO': 'fashionunited.no',
  'nl-BE': 'fashionunited.be',
  'nl-NL': 'fashionunited.nl',
  'pl-PL': 'fashionunited.pl',
  'pt-BR': 'fashionunited.com.br',
  'pt-PT': 'fashionunited.pt',
  'ru-RU': 'fashionunited.ru',
  'sv-SE': 'fashionunited.se',
  'tr-TR': 'fashionunited.com.tr',
  'zh-CN': 'fashionunited.cn',
  'zh-HK': 'fashionunited.hk',
};

const getOriginByLocale = (sourceOrigin, locale) => getTargetOrigin(
  sourceOrigin,
  targetOriginSuffixes[locale],
);

export default getOriginByLocale;
