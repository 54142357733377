import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import LinkAndAnchor from '../../LinkAndAnchor';
import sortByLevenshtein from '../../../lib/sortByLevenshtein';
import { useSiteContext } from '../../SiteContext';

const CityLink = ({
  city,
  cities,
}) => {
  const { locale, jobsComplete, isCareerPage } = useSiteContext();
  const { query: { companySlug } } = useRouter();
  const [{ slug: citySlug } = {}] = sortByLevenshtein(
    [...cities],
    city.toLowerCase(),
    (currentCity) => currentCity.name.toLowerCase(),
  );
  const jobsRoutePrefix = isCareerPage ? 'careers' : 'jobs';
  const companyParameters = isCareerPage && companySlug
    ? { companySlug } : {};

  return citySlug && !isCareerPage
    ? (
      <LinkAndAnchor
        route={`${jobsRoutePrefix}.${locale}`}
        params={{
          citySlug,
          ...companyParameters,
        }}
        title={`Jobs in ${city}`}
        useRouter={jobsComplete}
        hoverUnderline
      >
        {city}
      </LinkAndAnchor>
    )
    : (
      <LinkAndAnchor
        route={`${jobsRoutePrefix}.onlyLocationSearch.${locale}`}
        params={{
          locationSearch: city,
          ...companyParameters,
        }}
        title={`Jobs in ${city}`}
        useRouter={jobsComplete}
        hoverUnderline
      >
        {city}
      </LinkAndAnchor>
    );
};

CityLink.propTypes = {
  city: PropTypes.string,
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
};

CityLink.defaultProps = {
  city: undefined,
  cities: [],
};

export default CityLink;
