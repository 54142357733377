import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { JobListItem } from '@fuww/library/src/List/JobList';
import { useApolloClient } from '@apollo/client';
import HiddenLoader from '@fuww/library/src/HiddenLoader';
import { useSiteContext } from '../SiteContext';
import FormattedDate from '../FormattedDate';
import CompanyLink from './JobSearch/CompanyLink';
import CityLink from './JobSearch/CityLink';
import JobCompanyLogo from './JobSearch/JobCompanyLogo';
import getJobEventTracker from '../../lib/getJobEventTracker';
import isSameDay from '../../lib/isSameDay';
import getEbpSpotlightProfile from '../../lib/getEbpSpotlightProfile';
import LocalizedJobLink from '../Dashboard/Jobs/LocalizedJobLink';

const Job = ({
  id,
  company,
  title,
  slug,
  teaser,
  city,
  address,
  addressRegion,
  buildingName,
  postalCode,
  publishedAt,
  highlight,
  profiles,
  cities,
  locales,
  defaultLocale,
  _id,
  showLoader,
}) => {
  const { locale, jobsComplete } = useSiteContext();
  const apolloClient = useApolloClient();
  const [loadingBadges, setLoadingBadges] = useState(true);

  const [jobPublishedToday, setJobPublishedToday] = useState(false);

  useEffect(() => {
    setJobPublishedToday(isSameDay(publishedAt));
    setLoadingBadges(false);
  }, [publishedAt]);

  const badges = [
    ...(jobPublishedToday ? ['new job'] : []),
    ...(highlight ? ['top job'] : []),
  ];

  const onClick = getJobEventTracker(
    apolloClient,
    'Meteor Job List to Job Navigation',
    {
      id, title, company, city, _id,
    },
  );

  const { query: { companySlug } } = useRouter();
  const ebpSpotlightProfile = getEbpSpotlightProfile(company, profiles);
  const isEBPHighlighted = ebpSpotlightProfile && !highlight && !companySlug;

  return (
    <JobListItem
      showLoader={showLoader}
      title={(
        <LocalizedJobLink
          title={title}
          onClick={onClick}
          id={id}
          slug={slug}
          locale={locales.includes(locale) ? locale : defaultLocale}
          useRouter={jobsComplete && locales.includes(locale)}
          target={null}
        >
          {title}
        </LocalizedJobLink>
      )}
      badgeLoader={loadingBadges ? <HiddenLoader /> : undefined}
      loadingBadges={loadingBadges}
      teaser={teaser}
      company={(
        <CompanyLink
          profiles={profiles}
          company={company}
        />
      )}
      city={(
        <CityLink
          city={city}
          cities={cities}
        />
      )}
      address={address}
      addressRegion={addressRegion}
      buildingName={buildingName}
      postalCode={postalCode}
      logo={company.logoUrls[0] && (
        <JobCompanyLogo
          title={title}
          logoUrls={company.logoUrls}
        />
      )}
      publishedAt={<FormattedDate value={publishedAt} />}
      badges={badges}
      ebpHighlighted={isEBPHighlighted}
      key={`job-${id}`}
    />
  );
};

Job.propTypes = {
  id: PropTypes.number.isRequired,
  company: PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string,
    logoUrls: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  teaser: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  address: PropTypes.string,
  addressRegion: PropTypes.string,
  buildingName: PropTypes.string,
  postalCode: PropTypes.string,
  publishedAt: PropTypes.string.isRequired,
  highlight: PropTypes.bool,
  profiles: PropTypes.arrayOf(
    PropTypes.shape({ company: PropTypes.shape({ name: PropTypes.string }) }),
  ),
  cities: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string }),
  ),
  locales: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  defaultLocale: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  showLoader: PropTypes.bool,
};

Job.defaultProps = {
  address: undefined,
  addressRegion: undefined,
  buildingName: undefined,
  postalCode: undefined,
  highlight: false,
  profiles: [],
  cities: [],
  showLoader: false,
};

export default Job;
