import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  JobList as JobListComponent,
  JobListMessage,
  JobListItem,
} from '@fuww/library/src/List/JobList';
import HiddenLoader from '@fuww/library/src/HiddenLoader';
import seededSampleSize from '../../../lib/seededSampleSize';
import jobsListQuery from '../../../lib/queries/jobs/jobsListQuery.graphql';
import withQuery from '../../../lib/withQuery';
import messages from '../../../lib/messages.mjs';
import Job from '../Job';
import loadMore from '../../../lib/queries/loadMore';
import LoadMore from '../../LoadMore';
import useSeed from '../../../lib/useSeed';
import {
  jobSearchListLogoImageConfigurations,
} from '../../../lib/imageConfigurations';

const JobList = withQuery(({
  data, loading, variables, fetchMore, alternative, showLoader,
}) => {
  const intl = useIntl();

  if (data?.jobsConnection?.edges?.length === 0) {
    if (alternative) {
      return null;
    }

    const { keywords, ...alternativeVariables } = variables;
    if (keywords) {
      return (
        <>
          <JobListMessage>
            {intl.formatMessage(messages['jobs.no_jobs_for_keywords'], {
              keywords: <span className="keywords">{keywords}</span>,
            })}
          </JobListMessage>
          <JobList
            alternative
            query={jobsListQuery}
            variables={alternativeVariables}
            errorMessage="Error loading alternative jobs"
            skip={showLoader}
          />
        </>
      );
    }

    return (
      <JobListMessage>
        <div>
          {intl.formatMessage(messages.oops)}
        </div>
        <div>
          {intl.formatMessage(messages['jobs.no_jobs_found_criteria'])}
        </div>
      </JobListMessage>
    );
  }

  const rows = data?.jobsConnection?.edges?.map(({ node: job }) => (
    <Job
      showLoader={showLoader}
      {...job}
      key={job.id}
    />
  )) ?? Array.from({ length: 3 }).map((_, index) => (
    <JobListItem
      // eslint-disable-next-line react/no-array-index-key
      key={`job-skeleton-${index}`}
      showLoader
    />
  ));

  return (
    <>
      <JobListComponent className="jobs-list-all">{rows}</JobListComponent>
      <LoadMore
        loading={loading}
        hasNextPage={data?.jobsConnection?.pageInfo?.hasNextPage ?? false}
        fetchMore={
            () => loadMore({
              variables: {},
            })(data, 'jobsConnection', fetchMore)
          }
      />
    </>
  );
}, { renderAlways: true });

const HIGHLIGHTED_JOBS_LIMIT = 2;

const JobListWithHighlights = withQuery(({
  jobVariables,
  data,
  skipMainJobsListQuery,
}) => {
  const seed = useSeed();
  const highlightedJobs = useMemo(
    () => seededSampleSize(
      [...(data?.jobsConnection?.edges ?? [])], HIGHLIGHTED_JOBS_LIMIT, seed,
    ).map(
      ({ node: job }) => (
        <Job {...job} highlight key={`job-${job.id}`} />
      ),
    ), [data?.jobsConnection?.edges, seed],
  );
  return (
    <>
      <div />
      {highlightedJobs.length > 0 ? (
        <JobListComponent highlighted>
          {highlightedJobs}
        </JobListComponent>
      ) : null}
      <JobList
        query={jobsListQuery}
        variables={jobVariables}
        errorMessage="Error loading jobs"
        loader={<HiddenLoader />}
        skip={skipMainJobsListQuery}
      />
    </>
  );
}, { renderAlways: true });

const JobListWithVariables = ({
  variables,
  skipHighlightedJobsQuery,
  skipMainJobsListQuery,
}) => {
  const {
    locales,
    category,
    profileIdsOrKeywords,
    first,
  } = variables;

  const highlightedJobsVariables = {
    locales,
    category,
    profileIdsOrKeywords,
    highlightLocale: locales && locales[0],
    highlightType: 'TOP_JOB',
    first,
    logoConfigurations: jobSearchListLogoImageConfigurations,
  };

  return (locales && locales[0]) ? (
    <JobListWithHighlights
      query={jobsListQuery}
      variables={highlightedJobsVariables}
      errorMessage="Error loading jobs"
      jobVariables={variables}
      loader={<HiddenLoader />}
      skip={skipHighlightedJobsQuery}
      skipMainJobsListQuery={skipMainJobsListQuery}
    />
  ) : (
    <JobList
      query={jobsListQuery}
      variables={variables}
      errorMessage="Error loading jobs"
      loader={<HiddenLoader />}
      skip={skipMainJobsListQuery}
    />
  );
};

JobListWithVariables.propTypes = {
  variables: PropTypes.shape({
    locales: PropTypes.arrayOf(PropTypes.string),
    category: PropTypes.string,
    profileIdsOrKeywords: PropTypes.shape({
      profileIds: PropTypes.arrayOf(PropTypes.string),
      keywords: PropTypes.string,
    }),
    positionId: PropTypes.string,
    cityId: PropTypes.string,
    first: PropTypes.number.isRequired,
  }).isRequired,
  skipHighlightedJobsQuery: PropTypes.bool.isRequired,
  skipMainJobsListQuery: PropTypes.bool.isRequired,
};

export default JobListWithVariables;
