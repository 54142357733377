import createAnalyticsEvent from './mutations/createAnalyticsEvent.graphql';
import getWindowUrl from './getWindowUrl.mjs';

export default function getJobEventTracker(
  client,
  category,
  job,
  eventType = 'CLICK',
) {
  return async () => {
    const {
      id, title, city, _id, company: { name: companyName },
    } = job;

    const label = JSON.stringify({
      id,
      title,
      city,
      company: companyName,
    });

    await client.mutate({
      mutation: createAnalyticsEvent,
      variables: {
        title: category,
        type: eventType,
        subjectType: 'Job',
        subjectId: _id,
        payload: label,
        url: getWindowUrl(),
      },
    });

    return true;
  };
}
