import React from 'react';
import PropTypes from 'prop-types';
import breakpoints from '@fuww/library/src/Theme/breakpoints';
import ImageWithUrls from '../../ImageWithUrls';

const JobCompanyLogo = ({
  title,
  logoUrls,
}) => (
  <ImageWithUrls
    imageUrls={logoUrls}
    title={title}
    alt={title}
    sizes={`
      (min-width: ${breakpoints.xssm}px) 100px,
      60px
    `}
    srcSet={`${logoUrls[1]
    } 100w, ${logoUrls[2]
    } 120w, ${logoUrls[3]
    } 200w`}
    placeholderLoadStrategy="instant"
    disableRoundedCorners
  />
);

JobCompanyLogo.propTypes = {
  title: PropTypes.string.isRequired,
  logoUrls: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
};

export default JobCompanyLogo;
