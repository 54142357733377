import React from 'react';
import PropTypes from 'prop-types';
import LinkAndAnchor from '../../LinkAndAnchor';
import getMostRelevantProfile from '../../../lib/getMostRelevantProfile';
import { useSiteContext } from '../../SiteContext';

const CompanyLink = ({
  profiles,
  company: { name, displayName },
}) => {
  const { locale, jobsComplete, isCareerPage } = useSiteContext();
  const [{ page: { slug: companySlug } = {} } = {}] = getMostRelevantProfile(
    profiles, name,
  );

  return (
    companySlug
      ? (
        <LinkAndAnchor
          route={isCareerPage ? `careers.${locale}` : `jobs.${locale}`}
          params={{ companySlug }}
          title={displayName}
          useRouter={jobsComplete}
          hoverUnderline
        >
          {displayName}
        </LinkAndAnchor>
      )
      : (
        <LinkAndAnchor
          route={`jobs.${locale}`}
          params={{ keywords: displayName }}
          title={displayName}
          useRouter={jobsComplete}
          hoverUnderline
        >
          {displayName}
        </LinkAndAnchor>
      )
  );
};

CompanyLink.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  profiles: PropTypes.arrayOf(
    PropTypes.shape({ company: PropTypes.shape({ name: PropTypes.string }) }),
  ),
};

CompanyLink.defaultProps = {
  profiles: [],
};

export default CompanyLink;
