import getMostRelevantProfile from './getMostRelevantProfile';
import { ProfileCompany, ProfilePage } from './graphql/api/graphql';

interface PartialProfile {
  company: Pick<ProfileCompany, 'fullName'>;
  page: Pick<ProfilePage, 'premium'>;
  hasTabs: boolean;
}

const getEbpSpotlightProfile = <P extends PartialProfile>(
  company: {
    name: string;
    imageUrls: (string | null)[];
    logoUrls: (string | null)[];
  },
  profiles: P[],
): P | undefined => {
  const {
    name: companyName,
  } = company;

  const ebpSpotlightProfiles = profiles.filter(
    ({ hasTabs, page: { premium } }) => (hasTabs && premium),
  );

  return getMostRelevantProfile(
    ebpSpotlightProfiles, companyName,
  )[0];
};

export default getEbpSpotlightProfile;
