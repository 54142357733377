import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useSiteContext } from '../../SiteContext';
import LinkAndAnchor from '../../LinkAndAnchor';
import getOriginByLocale from '../../../lib/getOriginByLocale';

const LocalizedJobLink = ({
  id,
  slug,
  locale,
  useRouter: shouldUseRouter,
  children,
  onClick,
  target,
  title,
}) => {
  const isFrenchMultilingualSite = ['fr-BE', 'fr-CA'].includes(locale);
  const { origin, isCareerPage } = useSiteContext();
  const { query: { companySlug } } = useRouter();

  return (
    <LinkAndAnchor
      route={isCareerPage ? `careers-job.${locale}` : `job.${locale}`}
      params={{
        id,
        slug,
        ...isCareerPage ? { companySlug } : {},
        ...isFrenchMultilingualSite ? {
          prefix: 'fr',
        } : {},
      }}
      useRouter={shouldUseRouter}
      target={target}
      origin={getOriginByLocale(origin, locale)}
      onClick={onClick}
      title={title}
      hoverUnderline
    >
      { children || locale }
    </LinkAndAnchor>
  );
};

LocalizedJobLink.propTypes = {
  id: PropTypes.number.isRequired,
  slug: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  useRouter: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  target: PropTypes.string,
  title: PropTypes.string,
};

LocalizedJobLink.defaultProps = {
  useRouter: false,
  children: null,
  onClick: null,
  target: '_blank',
  title: null,
};

export default LocalizedJobLink;
