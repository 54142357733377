import sortByLevenshtein from './sortByLevenshtein';
import { ProfileCompany } from './graphql/api/graphql';

interface PartialProfile {
  company: Pick<ProfileCompany, 'fullName'>;
}

const getMostRelevantProfile = <P extends PartialProfile>(
  profiles: P[],
  companyName: string,
) => sortByLevenshtein<P>(
    [...profiles],
    companyName.toLowerCase(),
    (profile) => profile.company.fullName.toLowerCase(),
  );

export default getMostRelevantProfile;
