import levenshtein from 'js-levenshtein';

const sortByLevenshtein = <T>(
  items: T[],
  text: string,
  mapper: (item: T) => string,
): T[] => items
    .sort((firstItem, secondItem) => {
      const firstDistance = levenshtein(mapper(firstItem), text);
      const secondDistance = levenshtein(mapper(secondItem), text);

      if (firstDistance < secondDistance) {
        return -1;
      }

      if (firstDistance > secondDistance) {
        return 1;
      }

      return 0;
    });

export default sortByLevenshtein;
